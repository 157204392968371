import React, { FC, useEffect, useState } from "react";
import Agent from "../../models/Agent";
import { formatPhoneNumber } from "../../utils";
import axios from "axios";
import cx from "classnames";
import HeaderLogoBar from "./HeaderLogoBar";

type HeaderProps = {
  agentState?: Agent;
};

export const Header: FC<HeaderProps> = ({ agentState }) => {
  const [findADocOpen, setFindADocOpen] = useState(false);
  const [copyState, setCopyState] = useState("Share");
  const [url, setUrl] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      setFindADocOpen(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function copy(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    var dummy = document.createElement("input"),
      text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setCopyState("Copied!");
    setTimeout(() => {
      setCopyState("Share Link");
    }, 2000);
  }

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  const [smsActionLoading, setSmsActionLoading] = useState(false);
  const [emailActionLoading, setEmailActionLoading] = useState(false);

  const smsWebhook = async () => {
    setSmsActionLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_ZAPIERQUOTESMS}`,
        JSON.stringify({
          payload: {
            agentName: `${
              agentState?.firstName &&
              agentState?.lastName &&
              agentState.firstName + " " + agentState?.lastName
            }`,
            memberPhone: "chau",
            url: url,
          },
        })
      )
      .then((res) => {
        setSmsActionLoading(false);
        // console.log(res); // Check Response
      })
      .catch((err) => {
        setSmsActionLoading(false);
        // console.log(err)
      });
  };

  const emailWebhook = async () => {
    setEmailActionLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_ZAPIERQUOTEEMAIL}`,
        JSON.stringify({
          payload: {
            agentName: `${
              agentState?.firstName &&
              agentState?.lastName &&
              agentState.firstName + " " + agentState?.lastName
            }`,
            agentEmail: `${agentState?.email && agentState?.email}`,
            memberEmail: "hola",
            url: url,
          },
        })
      )
      .then((res) => {
        setEmailActionLoading(false);
        // console.log(res); // Check Response
      })
      .catch((err) => {
        setEmailActionLoading(false);
        // console.log(err);
      });
  };

  return (
    <>
      <HeaderLogoBar agentState={agentState} /> 
      <div
        id="header-buttons"
        className="top-0 z-30 w-full bg-orange pb-1 md:sticky"
      >
        <div className="w-full bg-navyBlue">
          <div className="flex flex-col items-center justify-center gap-2 py-[22px] px-2 font-inter text-white md:items-start md:justify-start lg:container lg:flex-row">
            <div className="flex flex-col gap-[15px] xs:flex-row">
              <a
                className={cx(
                  "flex h-[29px] w-[145px]",
                  "cursor-pointer items-center justify-center text-center",
                  "rounded-lg bg-celadonBlue",
                  "my-auto text-sm font-normal text-white"
                )}
                href="https://providers.online.metlife.com/findDentist?searchType=findDentistRetail&planType=DPPO"
                target="_blank"
                rel="noreferrer"
              >
                Dental Doc Search
              </a>
              <div className="group relative text-center text-base  font-normal">
                <a
                  className={cx(
                    "flex h-[29px] w-[145px]",
                    "cursor-pointer items-center justify-center text-center",
                    "rounded-lg bg-celadonBlue",
                    "text-sm font-normal text-white"
                  )}
                  href="https://www.vsp.com/eye-doctor"
                  target="_blank"
                  rel="noreferrer"
                >
                  Vision Doc Search
                </a>
              </div>
            </div>

            {/*
            <div className="ml-1 flex gap-[15px]">
              <div className="group relative text-center text-base font-normal  md:block">
                <div
                  className="flex h-[29px] w-[29px] cursor-pointer items-center justify-center rounded-full bg-celadonBlue text-center text-base font-normal text-white"
                  onClick={(event) => copy(event)}
                >
                  {copyState === "Copied!" ? (
                    <p> &#10004;</p>
                  ) : (
                    <img
                      alt="Share Icons"
                      className="h-[18px]"
                      src="/share-nodes.png"
                    />
                  )}
                </div>
              </div>
            </div>
             */}
            {/*
              <div
                onClick={() => (emailActionLoading ? null : emailWebhook())}
                className={`flex h-[29px] w-[29px]  items-center justify-center rounded-full ${
                  emailActionLoading
                    ? "bg-lightBlue"
                    : "cursor-pointer bg-celadonBlue"
                }`}
              >
                <img alt="Mail Icon" className="h-[22px]" src="/envelope.svg" />
              </div>
              <div
                onClick={() => (smsActionLoading ? null : smsWebhook())}
                className="flex h-[29px] w-[29px] cursor-pointer items-center justify-center rounded-full bg-celadonBlue"
              >
                <img alt="Phone Icon" className="h-[22px]" src="/phone.svg" />
              </div>
            </div>
             */}
          </div>
        </div>
      </div>
    </>
  );
};
