import { DocusealForm } from '@docuseal/react'
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';
import { recordRumEvent, RUM_EVENTS } from '../../services/awsRum';
import HeaderLogoBar from '../../components/Header/HeaderLogoBar';
import { Button } from '@material-tailwind/react';
import TypeformAgentOnboarding from './typeformAgentOnboarding';
import useFrontChat from '../../hooks/useFrontChat';
import { v4 } from 'uuid';

const FRONT_CHAT_ID = 'cbe12cb4c3b466da5cea178375f11140';

const AgentOnboarding= () => {
  // note: typeform publish url will include "hidden / url parameters" after the hash sign. 
  // We manually edit the url to turn them into to query params accessible via search below:
  const { search } = useLocation();
  const step = new URLSearchParams(search).get("step");
  const signer = new URLSearchParams(search).get("signer") || undefined;
  const utmSource = new URLSearchParams(search).get("utm_source") || 'xxxxx';
  const brokerStatus = new URLSearchParams(search).get("broker_status") || 'xxxxx';
  const refAgent = new URLSearchParams(search).get("ref_agent") || 'xxxxx';
  const entity = new URLSearchParams(search).get("n") || '';
  const company = new URLSearchParams(search).get("c") || '';
  const uuid = v4();

  useEffect(() => {
    const eventData = {utmSource, refAgent, brokerStatus, step, email: signer, uuid}
    console.log('eventData is', eventData)
    if (step === '1') {
      recordRumEvent(RUM_EVENTS.AGENT_ONBOARDING_INIT, eventData)
    }
    if (step === '3') {
      recordRumEvent(RUM_EVENTS.AGENT_ONBOARDING_COMPLETE, eventData)
    }
  }, [step])


  useFrontChat(FRONT_CHAT_ID);

  return (
    <div>
      <div className="top-0 z-50 w-full">
        <HeaderLogoBar />
      </div>
      <section className="p-8 bg-white">
        {!step &&
          <div>
            <a href={window.location.search.length > 0 ? window.location.href + '&step=1' : window.location.href + '?step=1'}>
              <Button variant="outlined" className="flex items-center gap-2 hover:bg-lightGrey">
                Agent Onboarding{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                  />
                </svg>
              </Button>
            </a>
          </div>}
        {step === '0' && (
          <>
          <form action='npnLookup'>
            <label>What is your NPN number?</label>
            <input type='text'></input>
            <button type='submit'>Next</button>
          </form>
          Lookup NPN here: https://nipr.com/help/look-up-your-npn
          We will look up your registration upon submission and guide you to the next step
          </>
        )}
        {step === '1' && (
          <>
            <TypeformAgentOnboarding refAgent={refAgent} utmSource={utmSource} brokerStatus={brokerStatus} />
          </>
        )}
        {step === '2' && (
          <>
            <div className="app">
              {/*EIN regex ^(\d{2}-\d{7})|(\d{2} \d{7})|(\d{2}\d{7})$ */}
              <DocusealForm
                src="https://docuseal.com/d/GvLj4gFZazF8zK"
                email={signer}
                metadata={{uuid, email: signer}}
                onComplete={() => console.log(`Document completed!`)}
                completedRedirectUrl="/agent-onboarding?step=3&complete=true"
                values={{'Name of Entity/Individual': entity, 'Business Name': company}} // pre-fill values here
                externalId={uuid}
              />
            </div>
          </>
        )
        }
        {step === '3' && (
          <>
            <div className="p-6 mt-10">
              <h1 className='text-2xl'>
                Thank you for completing the NCD Agent Enrollment!
              </h1>
              <p className='text-l mt-10'>
                Our Agent Support team will be in touch shortly. Look out for an email with your ID number and personal enrollment link that will allow access to the back office to manage your account. <br />
              </p>
              <p className='text-l mt-10'>
                If you have any questions, please contact:
              </p>
              <p className='text-l'>
                NCD Phone: 844-284-4944 <br />
                E-mail: AgentSupport@NCD.com
              </p>
              <p className="p-1 mt-10">
                <a href='https://agents.ncd.com/'>
                  <Button variant="outlined" className="flex items-center gap-2 hover:bg-lightGrey">
                    Agent Launchpad{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                      />
                    </svg>
                  </Button>
                </a>
              </p>
            </div>
          </>
        )
        }
      </section>
    </div>
  )
}

export default AgentOnboarding;