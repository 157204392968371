import { Widget } from "@typeform/embed-react"

type typeformAgentOnboardingProps = {
  refAgent: string;
  utmSource: string;
  brokerStatus: string;
}
const TypeformAgentOnboarding = ({refAgent, utmSource, brokerStatus}: typeformAgentOnboardingProps) => {
  return (
    <>
      {/* See all options here: https://github.com/Typeform/embed/blob/main/packages/embed/README.md#options */}
      <Widget id="MjtDyfBF" autoResize={true} transitiveSearchParams={true} hidden={{refAgent, utmSource, brokerStatus}} className="my-form" />
    </>
  )
}

export default TypeformAgentOnboarding