import { FC } from "react";
import { formatPhoneNumber } from "../../utils";
import Agent from "../../models/Agent";

type HeaderLogoBarProps = {
  agentState?: Agent;
}

const HeaderLogoBar: FC<HeaderLogoBarProps> = ({agentState}) => {
  return (
    <>
      <div
        id="header"
        className="top-0 left-0 w-full bg-white pb-2 md:pb-0 lg:h-20"
      >
        <div className="flex flex-col justify-center gap-[0.35rem] px-5 sm:flex-row sm:justify-between sm:gap-5 lg:container lg:h-20">
          <div className="flex flex-row items-end justify-center sm:justify-end">
            <img
              src="/ncd_logo.svg"
              className="h-[35px] sm:h-20 "
              alt="NCD Logo"
            />
            <div className="mx-[9px] whitespace-nowrap pb-[10px] text-sm font-medium leading-[17px] text-celadonBlue">
              by
            </div>
            <div className="flex items-center pt-[18px] sm:pr-0 sm:pt-0">
              <img
                src="/metlife-new-logo.svg"
                className="h-[28px] w-[90px] sm:h-auto sm:w-auto sm:self-end sm:pb-[9px]"
                alt="Metlife Logo"
              />
              {/*
              <div className="text-sm font-medium text-celadonBlue sm:ml-[13px] sm:self-end sm:pb-[10px]">
                &
              </div>
               */}
              <img
                src="/vsp-new-logo.svg"
                className="h-[28px] w-[50px] sm:ml-[9px] sm:h-auto sm:w-auto sm:self-end sm:pb-[4px]"
                alt="Metlife Logo"
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-2 px-[5px] text-xs sm:self-center sm:px-0 sm:text-base">
            <div
              className={`flex flex-row sm:flex-col ${!agentState && "hidden"}`}
            >
              <div className="font-bold text-navyBlue sm:text-end">
                {agentState?.firstName} {agentState?.lastName}
              </div>

              {agentState?.phoneNumber && (
                <>
                  <div className="px-1 sm:hidden">|</div>
                  <div className="font-semibold text-navyBlue sm:text-end">
                    {formatPhoneNumber(agentState?.phoneNumber?.toString())}
                  </div>
                </>
              )}

              {agentState?.email && (
                <>
                  <div className="px-1 sm:hidden">|</div>
                  <div className="font-semibold text-navyBlue">
                    {agentState?.email}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default HeaderLogoBar;