import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Agent from "./models/Agent";
import Member from "./models/Member";
import AgentLogin from "./views/AgentLogin";
import Completed from "./views/Completed";
import Flow from "./views/Flow";
import { recordRumEvent, RUM_EVENTS } from "./services/awsRum";
import AgentOnboarding from "./views/AgentOnboarding";

function App() {
  const [member, setMember] = useState(new Member());
  const [agentState, setAgentState] = useState(new Agent());

  useEffect(() => {
    recordRumEvent(RUM_EVENTS.APP_INIT)
  }, [])

  return (
    <BrowserRouter>
      <Routes>

        <Route path="/" element={
          <AgentLogin
            agentState={agentState}
            setAgentState={setAgentState}
          />}
        />
        <Route path="/agent-onboarding" element={
          <AgentOnboarding />
        }
        />
        <Route path="/:agentId">
          <Route
            index
            element={
              <Flow
                agentState={agentState}
                memberInstance={member}
                setAgentState={setAgentState}
                setMemberInstance={setMember}
              />
            }
          />
          <Route
            path=":appId"
            element={
              <Flow
                agentState={agentState}
                memberInstance={member}
                setAgentState={setAgentState}
                setMemberInstance={setMember}
              />
            }
          />
        </Route>
        <Route
          path="/completed/:agentId"
          element={<Completed agentState={agentState} memberInstance={member} setMemberInstance={setMember}/>}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
