import { useEffect } from "react";

/* Loads the front chat widget SDK and inits the chat widget
 * https://dev.frontapp.com/docs/chat-overview
 * FrontChat init options: https://dev.frontapp.com/docs/chat-sdk-reference
 */
const useFrontChat = (chatId: string) => {
  return useEffect(() => {
    let script2: HTMLScriptElement;
    const script = document.createElement('script')
    script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js'
    script.async = true
    script.id = 'front-script';

    script.onload = () => {
      script2 = document.createElement('script');
      script2.type = 'text/javascript';
      script2.id = `front-script-init-${chatId}`;
      script2.text = `
        window.FrontChat('init', {
          chatId: '${chatId}',
          useDefaultLauncher: true
        });
      `;
      if(!document.getElementById(`front-script-init-${chatId}`)){
        document.body.appendChild(script2);
      }
    }
    // so that multiple invocations of the hook do not append the script multiple times
    if(!document.getElementById('front-script')) {
      document.body.appendChild(script)
    }
    return () => {
      document.body.removeChild(script)
      if (script2) {
        document.body.removeChild(script2)
      }
    }
  }, [chatId])
}

export default useFrontChat;