import { IFeatureFlags } from './FeatureFlagsInterface';
import * as PlanIds from '../../planIds';

export default class FeatureFlags implements IFeatureFlags {
    planExclusions: { [key: string]: boolean };

    constructor(
        planExclusions?: { [key: string]: boolean }, 
        createdAt: Date = new Date() // Default to current date if not provided
    ) {
        this.planExclusions = planExclusions ?? this.getPlanExclusionsFromDate(createdAt);
    }

    private getPlanExclusionsFromDate(date: Date): { [key: string]: boolean } {
        const noSmilePlansAfterDate = new Date('2025-02-14');
        const showSmilePlans = date < noSmilePlansAfterDate;
        return {
            [PlanIds.NCDSmile2500byMetLife] : showSmilePlans, 
            [PlanIds.NCDSmile1000byMetLife] : showSmilePlans
        };
    }
}
